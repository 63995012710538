import { isThin } from '@/lib/api/apiUtils';
import { ObjectKeys, isDev } from '@/lib/utils';
import { Logger } from '@/logger';
import type {
  Address,
  BaseField,
  BaseInstance,
  FieldValue,
  IBaseInstanceConfig,
  ManyNonNullRelationalFieldValue,
  NonNullRelationalFieldValue,
  RelationField,
  RelationalFieldValue,
} from '@pigello/pigello-matrix';
import {
  AddressConfig,
  MonitoredInstanceActionConfig,
  getConfig,
} from '@pigello/pigello-matrix';
import chalk from 'chalk';
import type { ListResponse, Meta } from './types';

const logger = new Logger('instance:mapper', chalk.bgWhite.black);

const isMonitoringInstanceData = <Instance extends BaseInstance>(
  config: IBaseInstanceConfig<Instance>,
  instanceData: Instance
) => {
  const isMonitoringInstance = 'mt_added_time' in instanceData;

  if (isMonitoringInstance) {
    return true;
  }

  if (isDev()) {
    if (!config.canBeMonitored && isMonitoringInstance) {
      logger.error(
        "Got a monitoring instance but the config doesn't support monitoring. model name:",
        config.modelName
      );
      return false;
    }

    if (!config.monitoringConfig && isMonitoringInstance) {
      logger.error(
        "Got a monitoring instance but the config doesn't have a monitoring config. model name:",
        config.modelName
      );
      return false;
    }
  }

  return false;
};

type ConvertResponse<Instance extends BaseInstance> = {
  config: IBaseInstanceConfig<Instance>;
  response: ListResponse<Instance>;
};

export const convertResponse = async <Instance extends BaseInstance>({
  response,
  config,
}: ConvertResponse<Instance>) => {
  const returnList = { data: { list: [{}], meta: {} } };
  const newData: Instance[] = [];

  for (const instanceData of response.list) {
    const isMonitoringInstance = isMonitoringInstanceData(config, instanceData);

    if (isMonitoringInstance && config.monitoringConfig) {
      const mConfig = await getConfig<Instance>(config.monitoringConfig);

      newData.push(await toInternalFieldNames<Instance>(mConfig, instanceData));

      continue;
    }

    newData.push(await toInternalFieldNames(config, instanceData));
  }

  returnList.data.list = newData;
  returnList.data.meta = response.meta;
  return { ...returnList } as {
    data: { list: Instance[]; meta: Meta };
  };
};

export const getRelationFieldNames = <Instance extends BaseInstance>(
  config?: IBaseInstanceConfig<Instance>
) => {
  const names: Array<keyof Instance> = [];
  if (!config) return names;

  for (const [fieldName, field] of Object.entries(config.fields)) {
    if (field.type === 'manyrelation' || field.type === 'relation')
      names.push(fieldName as keyof Instance);
  }

  return names;
};

export const getOnlyManyRelationFieldNames = <Instance extends BaseInstance>(
  config?: IBaseInstanceConfig<Instance>
) => {
  const names: Array<keyof Instance> = [];
  if (!config) return names;

  for (const [fieldName, field] of Object.entries(config.fields)) {
    if (field.type === 'manyrelation') names.push(fieldName as keyof Instance);
  }

  return names;
};

export const isRelationField = (field: BaseField) => {
  return field.type === 'manyrelation' || field.type === 'relation';
};

export const toInternalFieldNames = async <Instance extends BaseInstance>(
  config: IBaseInstanceConfig<Instance>,
  data: Instance
) => {
  const newData = ObjectKeys(config.fields).reduce<Instance>((acc, key) => {
    const fieldConfig = config.fields[key];
    acc[key] = data[fieldConfig.externalFieldName as keyof Instance];

    return acc;
  }, {} as Instance);

  for (const specialField of ObjectKeys(data ?? {})) {
    if (String(specialField).startsWith('__')) {
      newData[specialField] = data[specialField];
    }
  }
  for (const fieldName in config.fields) {
    const fieldConfig = config.fields[fieldName];
    newData[fieldName as keyof Instance] =
      data[fieldConfig.externalFieldName as keyof Instance];
  }

  if ('mt_added_time' in data) {
    for (const fieldName in MonitoredInstanceActionConfig.fields) {
      const fieldConfig =
        MonitoredInstanceActionConfig.fields[
          fieldName as keyof typeof MonitoredInstanceActionConfig.fields
        ];

      newData[fieldName as keyof Instance] =
        data[fieldConfig.externalFieldName as keyof Instance];
    }
  }

  const relationFields = getRelationFieldNames<Instance>(config);

  for (const fieldName of relationFields) {
    const fieldConfig = config.fields[fieldName] as RelationField<Instance>;

    let fieldValue = data[
      fieldConfig.externalFieldName as keyof Instance
    ] as Instance;

    if (!fieldValue) continue;

    if (fieldConfig.type === 'manyrelation' && Array.isArray(fieldValue)) {
      for (let i = 0; i < fieldValue.length; i++) {
        if (isThin(fieldValue[i])) continue;

        const relationConfig = await getConfig<Instance>(
          fieldConfig.relationConfig
        );

        fieldValue[i] = await toInternalFieldNames<Instance>(
          relationConfig,
          fieldValue[i]
        );
      }
    } else if (!isThin(fieldValue)) {
      const relationConfig = await getConfig<Instance>(
        fieldConfig.relationConfig
      );

      fieldValue = await toInternalFieldNames<Instance>(
        relationConfig,
        fieldValue as Instance
      );
    }

    newData[fieldName] = fieldValue as Instance[keyof Instance];
  }

  return newData;
};

export const toExternalFieldNames = async <Instance extends BaseInstance>(
  config: IBaseInstanceConfig<Instance>,
  instance: Partial<Instance>,
  convertRelations = false
) => {
  const convertedData = ObjectKeys(instance).reduce<Record<string, unknown>>(
    (acc, key) => {
      const fieldConfig = config.fields[key];
      if (instance?.[key] === undefined) {
        return acc;
      }

      acc[fieldConfig?.externalFieldName ?? key] = instance[key];

      return acc;
    },
    {}
  );

  if (!convertRelations) {
    const relationFields = getRelationFieldNames(config);
    for (const relationFieldName of relationFields) {
      const fieldConfig = config.fields[relationFieldName];

      const currentVal: BaseInstance = convertedData[
        fieldConfig.externalFieldName
      ] as BaseInstance;

      if (currentVal && currentVal.id) {
        convertedData[fieldConfig.externalFieldName] = {
          id: currentVal.id,
        };
      } else if (Array.isArray(currentVal) && currentVal.length > 0) {
        convertedData[fieldConfig.externalFieldName] = currentVal.map((c) => ({
          id: c.id,
        }));
      } else {
        if (fieldConfig.isAddressField && currentVal) {
          convertedData[fieldConfig.externalFieldName] =
            await toExternalFieldNames(
              AddressConfig,
              convertedData[fieldConfig.externalFieldName] as Address
            );
          continue;
        }

        if (convertedData[fieldConfig.externalFieldName] === null) {
          continue;
        }

        if (
          fieldConfig?.type === 'manyrelation' &&
          Array.isArray(currentVal) &&
          currentVal.length === 0
        ) {
          continue;
        }

        delete convertedData[fieldConfig.externalFieldName];
      }
    }

    return convertedData as Instance;
  }

  const relationFields = getRelationFieldNames(config);

  for (const fieldName of relationFields) {
    const fieldConfig = config.fields[fieldName] as RelationField<Instance>;

    let fieldValue = instance[fieldName] as
      | RelationalFieldValue<Instance>
      | RelationalFieldValue<Instance>[];

    if (fieldValue === undefined) continue;

    let newValue = fieldValue;

    if (fieldConfig.type === 'manyrelation' && Array.isArray(newValue)) {
      fieldValue = fieldValue as ManyNonNullRelationalFieldValue<Instance>;
      newValue = [];
      const relationConfig = await getConfig<Instance>(
        fieldConfig.relationConfig
      );

      for (let i = 0; i < fieldValue.length; i++) {
        const val = fieldValue[i];
        if (!val || isThin(val)) continue;

        newValue[i] = await toExternalFieldNames<Instance>(relationConfig, val);
      }
    } else if (!isThin(fieldValue)) {
      if (!fieldValue) continue;

      const relationConfig = await getConfig<Instance>(
        fieldConfig.relationConfig
      );

      newValue = await toExternalFieldNames<Instance>(
        relationConfig,
        fieldValue as NonNullRelationalFieldValue<Instance>
      );
    }

    convertedData[fieldConfig.externalFieldName] = newValue;
  }

  return convertedData as Instance;
};

export const toInternalFieldNamesArray = <Instance extends BaseInstance>(
  config: IBaseInstanceConfig<Instance>,
  externalFieldNames: FieldValue<string[]>
) => {
  const arr: Array<keyof Instance> = [];

  for (const internalName in config.fields) {
    const extName = config.fields[internalName].externalFieldName;
    if (externalFieldNames?.includes(extName)) arr.push(internalName);
  }

  return arr;
};
